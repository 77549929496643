// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-goals-js": () => import("./../../../src/pages/goals.js" /* webpackChunkName: "component---src-pages-goals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-coursesites-course-screencast-js": () => import("./../../../src/pages/projects/coursesites-course-screencast.js" /* webpackChunkName: "component---src-pages-projects-coursesites-course-screencast-js" */),
  "component---src-pages-projects-game-design-js": () => import("./../../../src/pages/projects/game-design.js" /* webpackChunkName: "component---src-pages-projects-game-design-js" */),
  "component---src-pages-projects-group-proposed-research-conference-paper-js": () => import("./../../../src/pages/projects/group-proposed-research-conference-paper.js" /* webpackChunkName: "component---src-pages-projects-group-proposed-research-conference-paper-js" */),
  "component---src-pages-projects-id-model-e-learning-js": () => import("./../../../src/pages/projects/id-model-eLearning.js" /* webpackChunkName: "component---src-pages-projects-id-model-e-learning-js" */),
  "component---src-pages-projects-instructional-design-and-assessment-report-js": () => import("./../../../src/pages/projects/instructional-design-and-assessment-report.js" /* webpackChunkName: "component---src-pages-projects-instructional-design-and-assessment-report-js" */),
  "component---src-pages-projects-integrated-multimedia-project-js": () => import("./../../../src/pages/projects/integrated-multimedia-project.js" /* webpackChunkName: "component---src-pages-projects-integrated-multimedia-project-js" */),
  "component---src-pages-projects-internship-final-report-presentation-js": () => import("./../../../src/pages/projects/internship-final-report-presentation.js" /* webpackChunkName: "component---src-pages-projects-internship-final-report-presentation-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-learning-theory-research-paper-js": () => import("./../../../src/pages/projects/learning-theory-research-paper.js" /* webpackChunkName: "component---src-pages-projects-learning-theory-research-paper-js" */),
  "component---src-pages-projects-management-of-web-2-dot-0-tools-js": () => import("./../../../src/pages/projects/management-of-web-2dot0-tools.js" /* webpackChunkName: "component---src-pages-projects-management-of-web-2-dot-0-tools-js" */),
  "component---src-pages-projects-rapid-e-learning-tool-js": () => import("./../../../src/pages/projects/rapid-eLearning-tool.js" /* webpackChunkName: "component---src-pages-projects-rapid-e-learning-tool-js" */),
  "component---src-pages-projects-udl-website-js": () => import("./../../../src/pages/projects/udl-website.js" /* webpackChunkName: "component---src-pages-projects-udl-website-js" */),
  "component---src-pages-projects-www-722-id-project-js": () => import("./../../../src/pages/projects/www-722-id-project.js" /* webpackChunkName: "component---src-pages-projects-www-722-id-project-js" */),
  "component---src-pages-reflection-js": () => import("./../../../src/pages/reflection.js" /* webpackChunkName: "component---src-pages-reflection-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-summaries-internship-js": () => import("./../../../src/pages/summaries/internship.js" /* webpackChunkName: "component---src-pages-summaries-internship-js" */),
  "component---src-pages-summaries-portfolio-design-development-js": () => import("./../../../src/pages/summaries/portfolio-design-development.js" /* webpackChunkName: "component---src-pages-summaries-portfolio-design-development-js" */),
  "component---src-pages-summaries-professional-presentation-js": () => import("./../../../src/pages/summaries/professional-presentation.js" /* webpackChunkName: "component---src-pages-summaries-professional-presentation-js" */)
}

